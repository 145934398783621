import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../layout/layout'

import { Hero, Gallery } from '../components'

class GalleryIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const gallery = get(this, 'props.data.allContentfulGallery.edges')

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={`Gallery | ${siteTitle}`} />
          <Hero title='Gallery' size={[4, 6]} minHeight={[0, '30vh']} />
          <Gallery data={gallery} />
        </div>
      </Layout>
    )
  }
}

export default GalleryIndex

export const pageQuery = graphql`
  query GalleryIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulGallery(filter: { isFeatured: { eq: null } }) {
      edges {
        node {
          assets {
            title
            fluid(maxWidth: 1000, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
